import { isPlatformBrowser } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as localStorage from '@sitemule/core/utils/localStorage';
import { BehaviorSubject, Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { BasketService } from '../../core/basket/basket.service';
import { NotificationService } from '../../core/services/notification.service';
import { MfaDialogComponent } from '../components/mfa-dialog/mfa-dialog.component';
import {
  Address,
  CreateUserDTO,
  Customer,
  LoginData,
  SitemuleUserSession,
  User
} from '../models/User';
import { SitemuleUserMatLoginComponent } from '../user/dialogs/mat-login/mat-login.component';

export interface StoredUser {
  username: User['profile_id'];
  deviceId: User['deviceId'];
  hasPincode?: boolean;
}

const STORED_USERS_STORE = 'STORED_USERS';

class StoredUserService {
  private store: StoredUser[];

  constructor() {
    this.pupulateStoreFromLocalStorage();
  }

  public getUsers(): StoredUser[] {
    return this.store;
  }

  private getUserIndex(username: string): number {
    const loweredUsername = username.toUpperCase();

    return this.store.findIndex(
      ({ username: uName }) => uName.toUpperCase() === loweredUsername
    );
  }

  public getUser(username: string): StoredUser | undefined {
    return this.store[this.getUserIndex(username)];
  }

  public saveUser(user: StoredUser) {
    if (user.username) {
      const userIndex = this.getUserIndex(user.username);

      if (userIndex > -1) {
        this.store[userIndex] = {
          ...this.store[userIndex],
          ...user,
        };
      } else {
        this.store.push(user);
      }
      this.saveToLocalStorage();
    } else {
      throw new Error(`Couldn't save user without username. ${user}`);
    }
  }

  private pupulateStoreFromLocalStorage() {
    const storedUsers = localStorage.getItem(STORED_USERS_STORE);

    if (storedUsers) {
      try {
        this.store = JSON.parse(storedUsers);
        // TODO: Rethink this. We need backend session for some stuff due to basket and order handling.
        // either remove session based services and use localstored customer (bad because u can change client_id)
        // else change backend session to be alive for longer time and set a currentSession lifetime.
        // Underneath is a quickfix. Not something to be kept alive for longer time.
      } catch (e) {
        this.removeFromLocalStage();
        this.store = [];
      }
    } else {
      this.store = [];
    }
  }

  private saveToLocalStorage() {
    if (this.store && Array.isArray(this.store) && this.store.length) {
      localStorage.setItem(STORED_USERS_STORE, JSON.stringify(this.store));
    } else {
      this.removeFromLocalStage();
    }
  }

  private removeFromLocalStage() {
    localStorage.removeItem(STORED_USERS_STORE);
  }
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private storedUsers: StoredUserService;

  private readonly bc : BroadcastChannel | undefined = (() => {
    if (isPlatformBrowser(this.platformId) && typeof BroadcastChannel !== 'undefined') {
      return new BroadcastChannel('sitemule-webshop');
    }
    return undefined;
  })();
  private readonly sessionId = Date.now();

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private basketService: BasketService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    this.storedUsers = new StoredUserService();
    if (this.bc) {
      this.bc.onmessage = evt => {
        // Do not react on the message from same session
        if (evt.data.sessionId === this.sessionId) {
          return;
        }
        console.log(`User logged-in status changed. ${evt.data.type}`);
        this.bc.close();
        if (
          evt.data.type === 'login' ||
          evt.data.type === 'loginMFA' ||
          evt.data.type === 'setSellerAsClientCustomer' ||
          evt.data.type === 'setSellerAsCustomer' ||
          evt.data.type === 'removeSellerAsCustomer') {
          window.location.reload();
        }
        if (evt.data.type === 'logout') {
          window.location.replace('/');
        }
      };
    }
  }

  private triggerUserSessionChange(type: string) {
    if (this.bc) {
      this.bc.postMessage({
        type,
        sessionId: this.sessionId,
      });
    }
  }

  private currentSessionSource$: BehaviorSubject<SitemuleUserSession> =
    new BehaviorSubject(undefined);
  public currentSession$ = this.currentSessionSource$.asObservable();
  public currentuser$ = this.currentSession$.pipe(map((s) => s?.user));

  public originalUser$ = this.currentSessionSource$.pipe(map((sess) => {
    if (sess.workAsClient || sess.workAsCustomer) {
      return sess.originalUser;
    }

    return sess.user;
  }));
  public tryingToWorkAsClientOrCustomer$ =  new BehaviorSubject(false);

  get currentuser() {
    return this.currentSessionSource$.getValue()?.user;
  }

  get currentclient() {
    return this.currentSessionSource$.getValue();
  }

  public isLoggedIn$ = this.currentSession$.pipe(map((s) => !!s));

  load(): Promise<any> {
    return new Promise<void>((resolve) => {
      this.http.get('/cms/usr/getCurrentClient').subscribe((res1: any) => {
        if (res1.loggedIn) {
          this.currentSessionSource$.next(res1);
        }
        resolve();
      }, () => {
        resolve();
      });
    });
  }

  getAddresses() {
    const data = {
      search: '',
      pageno: 1,
      limit: 50,
    };
    return this.http.post<Address[]>('/cms/adr/listAddressesByCustomer', data);
  }

  createOrUpdateAddress(id: number = 0, address: Omit<Address, 'type'>): Observable<Address> {
    return this.http.post<Address>('/cms/adr/upsertAddress/' + id, address);
  }

  public createUser(data): Observable<any> {
    const userobj: CreateUserDTO = {
      profile_id: data.user.email,
      name: data.user.name,
      properties: {
        title: data.user.title,
        phone: data.user.phoneNumber,
        mobile: data.user.mobileNumber,
        company: {
          cvr: data.company.cvr,
          name: data.company.companyName,
          address1: data.company.addressOne,
          address2: data.company.addressTwo,
          zipcode: data.company.zipcode,
          city: data.company.city,
          country: data.company.country,
          phone: data.company.phoneNumber,
          invoiceMailAddress: data.company.email,
        },
      },
    };
    return this.http.post<CreateUserDTO>(
      '/cms/usr/createClientRequest',
      userobj
    );
  }

  // Update User is only infomation about the user. not access information (password, username)
  public updateUser(user: User) {
    this.http
      .post<any>('/cms/usr/updateClient', user)
      .pipe(first())
      .subscribe(() => {});
  }

  public getCVRByNumber(CVR: number) {
    return this.http.post<any>('/cms/cvr/getCVRbyNumber/' + CVR, {}).pipe(
      switchMap((value) => {
        if (value.hits?.hits.length == 0) {
          return throwError('None set');
        }
        return of(value.hits.hits[0]._source.Vrvirksomhed);
      })
    );
  }

  public isAllowed(permKey: string): Observable<boolean> {
    return this.getCurrentSession().pipe(
      map((res) => {
        return res.permissions[permKey] ? true : false;
      })
    );
  }

  public getStoredUsers(): StoredUser[] {
    return this.storedUsers.getUsers();
  }

  public updateCurrentSession(session: SitemuleUserSession) {
    if (session.user) {
      this.storedUsers.saveUser({
        username: session.user.profile_id,
        deviceId: session.user.deviceId,
      });
    }
    this.currentSessionSource$.next(session);
  }

  public getCurrentSession(): Observable<SitemuleUserSession> {
    return this.currentSessionSource$.asObservable();
  }

  public getSessionFromServer(): Observable<SitemuleUserSession> {
    return this.http.get<any>('/cms/usr/getCurrentClient').pipe(
      tap((res) => {
        this.updateCurrentSession(res);
      })
    );
  }

  public getCountries(): Observable<any[]> {
    return this.http.post<any[]>('/cms/adr/listCountries', {});
  }

  public showLoginDialog(config?: LoginData): Observable<any> {
    const dialogRef = this.dialog.open(SitemuleUserMatLoginComponent, {
      maxWidth: '100%',
      panelClass: 'small-dialog',
      data: config,
    });
    return dialogRef.afterClosed().pipe(first());
  }

  public login(
    username: string,
    password: string
  ): Observable<SitemuleUserSession> {
    const storedUser = this.storedUsers.getUser(username);
    return this.http
      .post<SitemuleUserSession>('/cms/user/login', {
        clientid: username,
        password: btoa(password),
        devId: storedUser ? storedUser.deviceId : '',
      })
      .pipe(
        tap(() => this.triggerUserSessionChange('login')),
        catchError((error: HttpErrorResponse) => {
          switch (error.status) {
            case 403:
              this.notificationService.show('Login failed', 'error');
              return throwError(error);
            case 404:
              this.notificationService.show('Login failed', 'error');
              return throwError(error);
            case 449:
              this.handleMFA(error.error);
              break;
            default:
              this.notificationService.show(error.message, 'error');
              return throwError(error);
          }
          return of(null);
        }),
        switchMap(() => {
          return this.getSessionFromServer();
        })
      );
  }

  getLists() {
    const search = {
      search: '',
      pageno: 1,
      limit: 50,
    };
    return this.http.post('/cms/lst/listLists', search);
  }

  getCustomers() {
    const data = {
      search: '',
      pageno: 1,
      limit: 50,
    };

    return this.http.post<Customer[]>('/cms/adr/listCustomers', data);
  }

  getEmployees(customerId) {
    return this.http.get('cms/usr/listClientByCustomer/' + customerId);
  }

  private handleMFA(res: { deviceId: string }) {
    const dialog1 = this.dialog.open(MfaDialogComponent, {
      height: '400px',
      width: '400px',
      data: {
        deviceId: res.deviceId,
        oneTimeKey: '',
      },
      panelClass: 'mfa-dialog',
    });
    dialog1.afterClosed().subscribe((result) => {
      this.loginMFA(result.deviceId, result.oneTimeKey)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.notificationService.show(error.message, 'error');
            return throwError(error);
          })
        )
        .subscribe(() => {});
    });
  }

  public loginMFA(deviceId: string, oneTimeKey: string) {
    return this.http
      .post<SitemuleUserSession>('/cms/user/loginMFA', { deviceId, oneTimeKey })
      .pipe(
        tap((res) => {
          if (res.user) {
            this.triggerUserSessionChange('loginMFA');
            this.updateCurrentSession(res);
          }
        })
      );
  }

  public logout(): Observable<SitemuleUserSession> {
    const currentSession = this.currentSessionSource$.getValue();

    if (currentSession) {
      if (currentSession.workAsClient || currentSession.workAsCustomer) {
        return this.removeSellerAsCustomer()
          .pipe(switchMap((res: any) => {
            if (res.success) {
              this.notificationService.show(
                'Du er nu på din egen profil igen',
                'success'
              );
              return this.getSessionFromServer();
            }
          }))
      }
      else {
        return this.http.post<SitemuleUserSession>('/cms/user/logoff', {}).pipe(
          tap(() => {
            this.triggerUserSessionChange('logout');
            window.location.reload();
          })
        );
      }
    }
  }

  public changePassword(
    userId: number,
    oldPassword: string,
    newPassword: string
  ) {
    return this.http.post<any>('/mnu/usr/changePassword/' + userId, {
      oldPassword: btoa(oldPassword),
      newPassword: btoa(newPassword),
    });
  }

  public setNewPassword(token: string, password: string) {
    return this.http.post('/mnu/usr/setNewPassword/' + token, {
      newPassword: btoa(password),
    });
  }

  public forgotPassword(username: string) {
    return this.http.post<any>('/cms/user/forgotPassword', { username });
  }

  // TODO: Implement this
  // @ts-ignore
  public loginPincode(username: string, devId: string, pincode: string) {}

  // TODO: Implement this
  // @ts-ignore
  public createPincode(devId: string, pincode: string) {}

  // Don't think this is going to be a thing.
  public getUserRegistrationForm() {
    return this.http.get('/cms/user/registrationForm');
  }

  public doesEmailExist(email: string) {
    return this.http
      .post('/mnu/usr/validateUser', {
        profile_id: email,
        name: 'Your name',
      })
      .pipe(
        map((res: any) => {
          return !res.success;
        })
      );
  }

  /** Either this or next BuildLogin(url?: string) **/
  buildLoginFormFromUrl(url: string) {
    return this.http.get(url);
  }

  buildLoginForm() {
    return {
      userInputType: 'email',
      registrationActive: true,
      forgotPasswordActive: false,
      title: 'Login',
    };
  }

  /** or this*/
  newbuildLoginForm(url?: string) {
    if (url) {
      this.http
        .get(url)
        .pipe(first())
        .subscribe((res) => {
          return res;
        });
    } else {
      return {
        userInputType: 'email',
        registrationActive: true,
        forgotPasswordActive: false,
        title: 'Login',
      };
    }
  }
  setSellerAsClientCustomer(client_id: string) {
    this.tryingToWorkAsClientOrCustomer$.next(true);
    return this.http.get('cms/usr/workAsClient/' + client_id)
      .pipe(
        tap(() => this.triggerUserSessionChange('setSellerAsClientCustomer')),
        switchMap(() => {
          return forkJoin([this.getSessionFromServer(), this.basketService.loadBasket()]);
        }),
      )
      .pipe(tap(() => {
        this.tryingToWorkAsClientOrCustomer$.next(false);
      }));
  }

  setSellerAsCustomer(customer_id: string) {
    this.tryingToWorkAsClientOrCustomer$.next(true);
    return this.http.get('cms/usr/workAsCustomer/' + customer_id)
      .pipe(
        tap(() => this.triggerUserSessionChange('setSellerAsCustomer')),
        switchMap(() => {
          return forkJoin([this.getSessionFromServer(), this.basketService.loadBasket()]);
        }),
      )
      .pipe(tap(() => {
        this.tryingToWorkAsClientOrCustomer$.next(false);
      }));
  }

  removeSellerAsCustomer() {
    return this.http.get('cms/usr/resetClient')
      .pipe(
        tap(() => this.triggerUserSessionChange('removeSellerAsCustomer')),
        switchMap(() => {
          return forkJoin([this.getSessionFromServer(), this.basketService.loadBasket()]);
        }),
      );
  }
}
